<template>
    <section class="mb-4">
        <label
            v-if="label"
            :for="'textarea-'+uid"
            class="form-label"
        >{{ label }}<Required v-if="isRequired" /></label>
        <textarea :id="'textarea-'+uid"
            @input="update"
            :rows="rows ?? 3"
            class="form-control"
            :class="{'is-invalid': isRequired && v$.content.$error }"
            @blur="v$.content.$touch"
            :placeholder="placeholder ?? ''"
        >{{ content }}</textarea>
        <div v-if="isRequired && v$.content.$error && v$.content.$errors" class="invalid-feedback">
            <span v-for="error in v$.content.$errors">{{ error.$message }}</span>
        </div>
    </section>
</template>

<script setup lang="ts">
import { helpers, requiredIf, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

const props = defineProps<{
    content: string;
    label?: string;
    rows?: number;
    isRequired?: boolean;
    placeholder?: string;
}>();

const emit = defineEmits<{
    (event: 'update', value: string): void,
}>();

const uid = ref<string|null>(null);

onMounted(() => {
    uid.value = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

});

const update = (event) => {
    emit('update',event.target.value)
};

/**
* VALIDATION
*/
const rules = {
    content: {requiredIf: helpers.withMessage('This field is required', requiredIf(() => props.isRequired))},
};
const v$ = useVuelidate(rules, props);
/** END VALIDATION **/
</script>
